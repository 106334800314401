// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Add this line to import Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEgdYfaICMAeDC0VXA7SbCDuo765gq5wM",
  authDomain: "ypsi2250-cd814.firebaseapp.com",
  projectId: "ypsi2250-cd814",
  storageBucket: "ypsi2250-cd814.appspot.com",
  messagingSenderId: "701364034723",
  appId: "1:701364034723:web:b2dc8b5f6eeef9e09bf316",
  measurementId: "G-06PS5SPPLY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get auth instance
const auth = getAuth(app);

// Get Firestore instance
const firestore = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { storage, firestore, auth, app, doc, setDoc };
