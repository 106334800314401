import React, { useEffect, useState } from 'react';
import { firestore, auth } from '../firebase.js';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import HamburgerMenu from './HamburgerMenu';
import "./ProfilePage.css"

function ProfilePage() {
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [checkIns, setCheckIns] = useState(0);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setEmail(currentUser.email);
                loadUserProfile(currentUser.uid);
            }
        });

        return () => unsubscribe();
    }, []);

    const loadUserProfile = async (userId) => {
        const docRef = doc(firestore, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            setFirstName(data.firstName || 'N/A');
            setLastName(data.lastName || 'N/A');
            setCheckIns(data.checkIns || 0);
        } else {
            setFirstName('N/A');
            setLastName('N/A');
            setCheckIns(0);
        }
    };

    const handleSignOut = () => {
        auth.signOut().then(() => {
            // Sign-out successful.
            setUser(null);
            window.location.href = "/";
        }).catch((error) => {
            // An error happened.
            console.error("Sign-out error: ", error);
        });
    };

    if (!user) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="profilePage">
            <HamburgerMenu currentUser={user} handleSignOut={handleSignOut} />
            <div className="profileContainer">
                <h1>User Profile</h1>
                <div className="formGroup">
                    <label>Email:</label>
                    <input type="email" value={email} disabled />
                </div>
                <div className="formGroup">
                    <label>First Name:</label>
                    <input type="text" value={firstName} disabled onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="formGroup">
                    <label>Last Name:</label>
                    <input type="text" value={lastName} disabled onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="formGroup">
                    <label>Check-ins:</label>
                    <input type="number" value={checkIns} disabled readOnly />
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;
