import React, { useState } from 'react';
import './HamburgerMenu.css'; // Ensure you link the correct CSS file

function HamburgerMenu({ currentUser, handleSignOut }) {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="userDetails" onClick={toggleDropdown}>
      {currentUser && currentUser.email} {/* Ensure currentUser is not null */}
      {/* Hamburger menu icon */}
      {showDropdown && (
        <div className="dropdownMenu">
          <a href="/profile">Profile</a>
          <a href="/settings">Settings</a>
          <button onClick={handleSignOut} style={{ cursor: 'pointer', background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline' }}>Sign Out</button> {/* Changed to button */}
        </div>
      )}
    </div>
  );
}

export default HamburgerMenu;
