import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase.js';
import HamburgerMenu from './HamburgerMenu'; // Ensure the path is correct
import './SettingsPage.css';

function SettingsPage() {
    const [user, setCurrentUser] = useState(null);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user); // Set the current user in state
                setEmail(user.email);
                loadUserProfile(user.uid);
            } else {
                navigate("/");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const loadUserProfile = async (userId) => {
        try {
            const docRef = doc(firestore, "users", userId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setFirstName(data.firstName || '');
                setLastName(data.lastName || '');
            }
        } catch (error) {
            console.error("Error loading user profile: ", error);
        }
    };
    
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handle2FAChange = () => {
        setIs2FAEnabled(!is2FAEnabled);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Implement the logic to handle form submission
        console.log('Settings updated:', {
            email,
            firstName,
            lastName,
            currentPassword,
            newPassword,
            is2FAEnabled,
        });
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
            navigate("/");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className="settingsPage">
            {user && (
                    <HamburgerMenu currentUser={user} handleSignOut={handleSignOut} />
                )}
                <h1>Settings</h1>
            <div className="settingsContainer">
                <form onSubmit={handleSubmit}>
                    <div className="formGroup">
                        <label>Email: </label>
                        <input type="email" value={email} readOnly />
                    </div>
                    <div className="formGroup">
                        <label>First Name: </label>
                        <input type="text" value={firstName} onChange={handleFirstNameChange} />
                    </div>
                    <div className="formGroup">
                        <label>Last Name: </label>
                        <input type="text" value={lastName} onChange={handleLastNameChange} />
                    </div>
                    <div className="formGroup">
                        <label>Current Password: </label>
                        <input type="password" value={currentPassword} onChange={handleCurrentPasswordChange} />
                    </div>
                    <div className="formGroup">
                        <label>New Password: </label>
                        <input type="password" value={newPassword} onChange={handleNewPasswordChange} />
                    </div>
                    <div className="formGroup">
                        <label>Confirm Password: </label>
                        <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                    </div>
                    <div className="formGroup">
                        <label>Multi-Factor Authentication: </label>
                        <input type="checkbox" checked={is2FAEnabled} onChange={handle2FAChange} />
                    </div>
                    <div className="centeredButton">
                        <button type="submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SettingsPage;
