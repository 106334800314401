import React from 'react';
import './HomePage.css'; // Ensure you have this CSS file for styling
import headerImage from '../assets/EaglesHeader.png'; // Import the header image

function HomePage() {
    return (
        <div className="homePage">
            <div className="headerImage" style={{ backgroundImage: `url(${headerImage})` }}></div>
            <h1>Welcome to the Fraternal Order of Eagles Ypsi #2250</h1>
            <div className="content">
                <section className="category">
                    <h2>Aerie</h2>
                    <p>
                        Aeries are the local units of the Fraternal Order of Eagles, spread across the US and Canada. 
                        Known for their community service and charitable actions, Aeries are central to the 
                        organization’s mission. They come together annually at the Grand Aerie International Convention 
                        to discuss and vote on new representatives and important issues.
                    </p>
                </section>
                <section className="category">
                    <h2>Auxiliary</h2>
                    <p>
                        The Auxiliary represents the women’s division within the Fraternal Order of Eagles, playing a 
                        crucial role in supporting the organization's charitable and community efforts. Historically, 
                        membership was exclusive to women, but recent changes have allowed for more inclusivity 
                        within Aeries.
                    </p>
                </section>
                <section className="category">
                    <h2>Eagle Riders</h2>
                    <p>
                        The Eagle Riders group is a part of the Fraternal Order of Eagles known for its passion for 
                        motorcycle riding and community service. They often organize rides and events to raise funds 
                        and awareness for various causes, embodying the Eagles' spirit of camaraderie and charity.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default HomePage;
