import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './AdminPage.css';
import HamburgerMenu from './HamburgerMenu'; // Assuming you have a HamburgerMenu component

function AdminPage() {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const auth = getAuth();
        const firestore = getFirestore();

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const docRef = doc(firestore, "users", currentUser.uid); // Ensure this is the correct path
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    if (docSnap.data().isA) {
                        setIsAdmin(true);
                    }
                } else {
                    console.log("No such document!");
                }
            } else {
                setUser(null);
                setIsAdmin(false);
            }
            setLoading(false); // Set loading to false once we have the user data
        });

        return () => unsubscribe();
    }, []);

    const handleSendNotification = async () => {
        if (topic && message) {
            try {
                const response = await fetch('https://us-central1-ypsi2250-cd814.cloudfunctions.net/sendNotification', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await user.getIdToken()}`
                    },
                    body: JSON.stringify({ topic, message })
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('Notification sent successfully:', data);
                } else {
                    console.error('Error sending notification:', response.statusText);
                }
            } catch (error) {
                console.error('Error sending notification:', error);
            }
        } else {
            alert('Please select a topic and enter a message');
        }
    };

    const handleSignOut = () => {
        getAuth().signOut().then(() => {
            console.log('User signed out');
        }).catch((error) => {
            console.error('Error signing out:', error);
        });
    };

    if (loading) {
        return <div>Loading...</div>; // Show loading state while user is being fetched
    }

    if (!isAdmin) {
        return <div className="notAuthorized">You are not authorized to view this page.</div>;
    }

    return (
        <div className="adminPage">
            <div className="header">
                <HamburgerMenu currentUser={user} handleSignOut={handleSignOut} />
                <h1>Admin Page</h1>
            </div>
            <div className="formGroup">
                <label htmlFor="topic">Select Topic:</label>
                <select id="topic" value={topic} onChange={(e) => setTopic(e.target.value)}>
                    <option value="">Select a topic</option>
                    <option value="aerieEvents">Aerie Events</option>
                    <option value="eagleRiderEvents">Eagle Rider Events</option>
                    <option value="auxiliaryEvents">Auxiliary Events</option>
                </select>
            </div>
            <div className="formGroup">
                <label htmlFor="message">Message:</label>
                <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Enter the notification message"
                ></textarea>
            </div>
            <button className="sendButton" onClick={handleSendNotification}>Send Notification</button>
        </div>
    );
}

export default AdminPage;
